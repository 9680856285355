<template>
  <main :class="{ result: order == contents.length }">
    <div
      class="navigation"
      v-show="order > 0"
    >
      <div
        class="navigation__progress-container"
        v-show="order !== contents.length"
      >
        <div :style="`width:${(order / (contents.length - 1)) * 100}%`"></div>
      </div>
      <div
        class="navigation__arrows"
        v-show="order > 0 && order < contents.length"
      >
        <div
          @click="prev"
          class="prev"
        >
          <svg
            version="1.1"
            id="레이어_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 13.6 8.2"
            style="enable-background: new 0 0 13.6 8.2"
          >
            <line
              class="st0"
              x1="13.8"
              y1="4.1"
              x2="0.1"
              y2="4.1"
            />
            <line
              class="st1"
              x1="13.8"
              y1="4.1"
              x2="1.2"
              y2="4.1"
            />
            <polygon points="4.5,0 5.2,0.7 1.6,4.1 5.2,7.4 4.5,8.2 0.1,4.1 			" />
          </svg>
        </div>
        <div
          @click="next"
          class="next"
        >
          <svg
            version="1.1"
            id="레이어_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 13.6 8.2"
            style="enable-background: new 0 0 13.6 8.2"
          >
            <line
              class="st0"
              x1="13.8"
              y1="4.1"
              x2="0.1"
              y2="4.1"
            />
            <line
              class="st1"
              x1="13.8"
              y1="4.1"
              x2="1.2"
              y2="4.1"
            />
            <polygon points="4.5,0 5.2,0.7 1.6,4.1 5.2,7.4 4.5,8.2 0.1,4.1 			" />
          </svg>
        </div>
      </div>
    </div>
    <div
      class="content"
      :class="[`content--${current.direction}`, { 'content--intro': order == 0 }]"
      v-if="order < contents.length"
    >
      <template v-if="order == 0">
        <div
          class="intro"
          :style="`background-image:url(${intro_back})`"
          @click="next"
        >
          <div class="intro-container">
            <img
              class="intro-title"
              :src="intro_text"
            />
            <img
              class="intro-tabstart"
              :src="intro_tabstart"
            />
          </div>
        </div>
      </template>
      <template v-else>
        <div class="indication">
          <h1
            class="question-number"
            v-show="current.type == `question` || current.type == `question_img`"
          >
            Q.{{ current.question_numb }}
          </h1>
          <article>
            <h1>{{ current.indication }}</h1>
            <p>{{ current.indication_sub }}</p>
          </article>
        </div>
        <div class="options">
          <article
            class="button"
            v-if="current.type == 'intro'"
            @click="next"
          >
            <div
              class="wavy"
              v-show="order == 0"
            >
              <span style="--i: 1">테</span>
              <span style="--i: 2">스</span>
              <span style="--i: 3">트</span>
              <span style="--i: 4"></span>
              <span style="--i: 5">시</span>
              <span style="--i: 6">작</span>
              <span style="--i: 7">하</span>
              <span style="--i: 8">기</span>
            </div>
            <div
              class="wavy"
              v-show="order > 0 && current.type == `intro`"
            >
              <span style="--i: 5">시</span>
              <span style="--i: 6">작</span>
              <span style="--i: 7">하</span>
              <span style="--i: 8">기</span>
            </div>
          </article>
          <article v-else-if="current.type == 'question_img'">
            <div
              class="img-container"
              v-for="option of current.options"
              v-bind:key="option.id"
              @click="next"
              :class="{ selected: option.selected }"
            >
              <div
                class="img"
                :style="`background-image:url(&quot;${option.option}&quot;)`"
                @click="get_answer(option)"
              />
            </div>
          </article>
          <article v-else>
            <li
              v-for="(option, index) of current.options"
              v-bind:key="option.id"
              @click="next"
              :class="{ selected: option.selected }"
            >
              <div
                class="text"
                @click="get_answer(option)"
              >
                <div class="answer-indicator">
                  {{
                    index == 0
                      ? `
              a.
              `
                      : `
             b.
              `
                  }}
                </div>
                {{ option.option }}
              </div>
            </li>
          </article>
        </div>
      </template>
    </div>
    <template v-else>
      <article>
        <div
          class="result__left"
          :style="`background-image:url('${intro_back}')`"
        >
          <div class="go-to-dc">
            <a
              href="https://community.notefolio.net/camp2023_super"
              target="_blank"
            >
              <img :src="outro_go_dc" />
            </a>
          </div>
          <div class="go-share">
            <img :src="outro_go_share" />
          </div>
          <div class="sns">
            <div
              class="facebook"
              @click="share_facebook"
            >
              <img :src="facebook" />
            </div>
            <div
              class="twitter"
              @click="share_twitter"
            >
              <img :src="twitter" />
            </div>
            <div
              class="link"
              @click="copyToClipboard"
            >
              <img :src="link_button" />
            </div>
          </div>
          <div
            class="retry"
            @click="reset"
          >
            <img :src="outro_retry" />
          </div>
        </div>
        <div class="result__right">
          <h1>당신의 디자인 성향은?</h1>
          <div class="graph-container">
            <div class="graph">
              <div class="title">문제제기와 해결성향</div>
              <Radar
                :data="radar_data_1"
                :options="options_1"
              />
            </div>
            <div class="graph">
              <div class="title">조형 성향</div>
              <Radar
                :data="radar_data_2"
                :options="options_2"
              />
            </div>
          </div>
          <div class="think">
            <h2>문제 제기와 해결 성향</h2>
            <p>
              <template
                v-for="t of thinks"
                :key="t"
              >
                <t v-if="t.selected">{{ t.title }}</t>
              </template>
            </p>
            <ul>
              <li
                v-for="t of thinks"
                :key="t"
              >
                <template v-if="t.selected">
                  <p>{{ t.content }}</p>
                </template>
              </li>
            </ul>
          </div>
          <div class="make">
            <h2>조형 성향</h2>
            <p>
              <template
                v-for="t of makes"
                :key="t"
              >
                <t v-if="t.selected">{{ t.title }}</t>
              </template>
            </p>
            <ul>
              <li
                v-for="t of makes"
                :key="t"
              >
                <template v-if="t.selected">
                  <p>{{ t.content }}</p>
                </template>
              </li>
            </ul>
          </div>
          <!-- <div
            v-if="st_width <= 800"
            class="reset mobile"
            @click="reset"
          >
            <span style="--i: 5">다</span>
            <span style="--i: 6">시</span>
            <span style="--i: 7">하</span>
            <span style="--i: 8">기</span>
          </div> -->
        </div>
      </article>
    </template>
    <div id="ds-logo">
      <img
        :src="logo"
        alt=""
      />
    </div>
    <div
      id="warning"
      v-show="warning.status"
    >
      <div class="text">'너의 디자인 성향 테스트' 링크가 복사되었습니다.</div>
    </div>
  </main>
</template>

<script>
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from "chart.js";
import { Radar } from "vue-chartjs";

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

let option_id = 0;
let question_numb = 1;
export default {
  name: "App",
  components: { Radar },
  data() {
    return {
      link_button: require("@/assets/link.png"),
      facebook: require("@/assets/facebook.png"),
      twitter: require("@/assets/twitter.png"),
      outro_go_dc: require("@/assets/outro-go-dc.png"),
      outro_go_share: require("@/assets/outro-go-share.png"),
      outro_retry: require("@/assets/outro-retry.png"),
      logo: require("@/assets/ds-logo.png"),
      intro_tabstart: require("@/assets/intro-tabstart.png"),
      intro_text: require("@/assets/intro-text.png"),
      intro_back: require("@/assets/intro-back.jpg"),
      st_width: 0,
      order: 0,
      ability: {
        embrace: 0,
        unique: 0,
        induction: 0,
        deduction: 0,
        free: 0,
        creative: 0,
        structural: 0,
        storyteller: 0,
      },
      options_1: {
        scale: {
          beginAtZero: true,
          max: 3,
          min: 0,
          stepSize: 1,
        },
        plugins: {
          legend: {
            labels: {
              boxWidth: 0,
            },
          },
        },
      },
      options_2: {
        scale: {
          beginAtZero: true,
          max: 5,
          min: 0,
          stepSize: 1,
        },
        plugins: {
          legend: {
            labels: {
              boxWidth: 0,
            },
          },
        },
      },
      radar_data_1: {
        labels: ["포용력있는", "협상가", "유니크한", "전문가"],
        datasets: [
          {
            label: "",
            fill: false,
            backgroundColor: "rgb(0,0,0)",
            borderColor: "rgb(0,0,0)",
            // // pointBackgroundColor: 'rgba(179,181,198,1)',
            // pointBorderColor: '#fff',
            // pointHoverBackgroundColor: '#fff',
            // pointHoverBorderColor: 'rgba(179,181,198,1)',
            borderWidth: 2,
            data: [1, 2, 3, 4],
            pointStyle: "rect",
          },
        ],
      },
      radar_data_2: {
        labels: ["다채로운", "창의 버튼", "구조적인", "스토리텔러"],
        datasets: [
          {
            label: "",
            fill: false,
            borderColor: "rgb(0,0,0)",
            borderWidth: 2,
            // // pointBackgroundColor: 'rgba(179,181,198,1)',
            // pointBorderColor: '#fff',
            // pointHoverBackgroundColor: '#fff',
            // pointHoverBorderColor: 'rgba(179,181,198,1)',
            data: [4, 5, 5, 3],
            pointStyle: "rect",
          },
        ],
      },

      contents: [
        {
          type: "intro",
          indication: `너의 디자인 테스트-디자이너의 재능 찾기`,
          indication_sub: `쉽고 재미있는 심리테스트로 내면의 디자인 재능을 발견하자`,
        },
        {
          type: "intro",
          indication: `문제제기와 해결 성향 테스트 Q.1-6`,
          indication_sub: `디자이너는 여러 동료, 협력자, 클라이언트와 일을 하면서 다양한 상황을 마주칩니다. 각각의 상황에서 당신은 어떤 식으로 반응하게 될지 선택하세요.`,
        },
        {
          type: "question",
          question_numb: question_numb++,
          indication: `

                      `,
          indication_sub: ` 중요한 프로젝트를 진행하는 중에 실험적인 디자인과 대중적인 디자인이 시안으로 나왔다. 결정은 당신에게 달렸다. 어떤 시안을 채택하면 좋을까?`,
          options: [
            {
              id: option_id++,
              option: `굳이 위험 부담을 질 필요는 없지. 모두가 좋아하는 대중적인 디자인`,
              value: "embrace",
            },
            {
              id: option_id++,
              option: `아무 것도 예측할 수 없지만… 난 이게 좋으니까! 실험적인 디자인`,
              value: "unique",
            },
          ],
          answer: null,
        },
        {
          type: "question",
          question_numb: question_numb++,
          indication: `

                      `,
          indication_sub: `프로젝트가 한참 진행 중인데 갑자기 새로운 아이디어가 떠올랐다. 당신의 행동은?`,
          options: [
            {
              id: option_id++,
              option: `타이밍이 좋지 않다. 기억해뒀다가 다음 기회에 제안한다.`,
              value: "embrace",
              selected: false,
            },
            {
              id: option_id++,
              option: `곧장 아이디어를 팀원들과 공유하고 설득한다.`,
              value: "unique",
              selected: false,
            },
          ],
          answer: null,
        },
        {
          type: "question",
          question_numb: question_numb++,
          indication: `

                      `,
          indication_sub: `완성된 디자인 결과물을 인스타그램에 올렸더니 ‘좋아요’가 25개 달렸다. 이때 나의 생각은?`,
          options: [
            {
              id: option_id++,
              option: `나의 디자인을 이해하는 사람이 25명이나 되다니. 나쁘지 않다.`,
              value: "unique",
              selected: false,
            },
            {
              id: option_id++,
              option: `25개 뿐이라니. 다음엔 더 많은 사람들이 좋아할 디자인을 하고 싶다.`,
              value: "embrace",
              selected: false,
            },
          ],
          answer: null,
        },
        {
          type: "question",
          question_numb: question_numb++,
          indication: `

                      `,
          indication_sub: `디자인 프로젝트를 진행하게 됐다. 어디서부터 시작하면 좋을까?`,
          options: [
            {
              id: option_id++,
              option: `클라이언트가 좋아할 만한 레퍼런스를 찾아본다.`,
              value: "induction",
              selected: false,
            },
            {
              id: option_id++,
              option: `클라이언트와 대화를 나눈다.`,
              value: "deduction",
              selected: false,
            },
          ],
          answer: null,
        },
        {
          type: "question",
          question_numb: question_numb++,
          indication: `

                      `,
          indication_sub: `디자인 시안을 제시할 때 어떤 식으로 하면 좋을까?`,
          options: [
            {
              id: option_id++,
              option: `내가 좋아하는 시안 하나, 버리는 시안 두 개를 제안해서 선택을 유도한다.`,
              value: "induction",
              selected: false,
            },
            {
              id: option_id++,
              option: `동등하게 좋은 세 가지 시안을 제안하고 클라이언트와 협의를 통해 선택한다.`,
              value: "deduction",
              selected: false,
            },
          ],
          answer: null,
        },
        {
          type: "question",
          question_numb: question_numb++,
          indication: `

                      `,
          indication_sub: `디자인 과정 중에 클라이언트가 불분명한 피드백을 줬다면 당신의 반응은?`,
          options: [
            {
              id: option_id++,
              option: `기본적인 컨셉부터 다시 챙겨본다.`,
              value: "deduction",
              selected: false,
            },
            {
              id: option_id++,
              option: `시안을 더 제시해서 선택의 폭을 넓힌다.`,
              value: "induction",
              selected: false,
            },
          ],
          answer: null,
        },
        {
          type: "intro",
          indication: `조형 성향 테스트 Q.7-12`,
          indication_sub: `제시되는 두 이미지 중에서 더 좋은 것을 3초 안에 고르세요. 최대한 빠르게, 즉흥적으로 선택해야 더욱 정확한 결과를 얻을 수 있습니다.`,
        },
        {
          type: "question_img",
          direction: "horizontal",
          question_numb: question_numb++,
          indication: ``,
          indication_sub: ``,
          options: [
            {
              id: option_id++,
              option: require(`@/assets/q7-1.jpeg`),
              value: ["free", "creative"],
              selected: false,
            },
            {
              id: option_id++,
              option: require(`@/assets/q7-2.jpeg`),
              value: ["structural", "storyteller"],
              selected: false,
            },
          ],
          answer: null,
        },
        {
          type: "question_img",
          direction: "vertical",
          question_numb: question_numb++,
          indication: ``,
          indication_sub: ``,
          options: [
            {
              id: option_id++,
              option: require(`@/assets/q8-1.jpeg`),
              value: ["structural", "creative"],
              selected: false,
            },
            {
              id: option_id++,
              option: require(`@/assets/q8-2.jpeg`),
              value: ["free", "storyteller"],
              selected: false,
            },
          ],
          answer: null,
        },
        {
          type: "question_img",
          direction: "vertical",
          question_numb: question_numb++,
          indication: ``,
          indication_sub: ``,
          options: [
            {
              id: option_id++,
              option: require(`@/assets/q9-1.jpeg`),
              value: ["structural", "creative"],
              selected: false,
            },
            {
              id: option_id++,
              option: require(`@/assets/q9-2.jpeg`),
              value: ["free", "storyteller"],
              selected: false,
            },
          ],
          answer: null,
        },
        {
          type: "question_img",
          direction: "horizontal",
          question_numb: question_numb++,
          indication: ``,
          indication_sub: ``,
          options: [
            {
              id: option_id++,
              option: require(`@/assets/q10-1.jpeg`),
              value: ["structural", "storyteller"],
              selected: false,
            },
            {
              id: option_id++,
              option: require(`@/assets/q10-2.jpeg`),
              value: ["free", "creative"],
              selected: false,
            },
          ],
          answer: null,
        },
        {
          type: "question_img",
          direction: "vertical",
          question_numb: question_numb++,
          indication: ``,
          indication_sub: ``,
          options: [
            {
              id: option_id++,
              option: require(`@/assets/q11-1.jpeg`),
              value: ["free", "storyteller"],
              selected: false,
            },
            {
              id: option_id++,
              option: require(`@/assets/q11-2.jpeg`),
              value: ["structural", "creative"],
              selected: false,
            },
          ],
          answer: null,
        },
      ],
      thinks: {
        unique: {
          title: "유니크한",
          count: 0,
          selected: false,
          content:
            "당신은 디자이너로서 독특한 정체성으로 두각을 나타낼 자질을 갖추고 있습니다. 이러한 기질을 잘 발전시킨다면 인지도 있는 디자인을 선보이고, 이를 좋아하는 팬이 생길 수도 있겠죠. 당신은 명확한 비전과 확신에 찬 설계를 밑바탕으로 창의적인 위험을 감수하는데 주저함이 없습니다. 자신을 믿으세요. 계속 재능을 갈고 닦는다면 고유의 시각적 언어와 접근 방식을 갖춘 응집력 있는 포트폴리오를 보유하게 될 것입니다.",
        },
        embrace: {
          title: "포용력있는",
          count: 0,
          selected: false,
          content:
            "당신은 여러 환경과, 제약과, 의견을 수집할 수 있는 넓은 포용력을 갖추고 있습니다. 이러한 자세를 바탕으로 광범위한 아이디어와 해결책을 생성하여 그 누구보다 균형 잡힌 디자인으로 나아가려는 성향이 있습니다. 당신은 디자이너로서 적응력과 유연성이 뛰어나며 필요에 따라 접근 방식과 전략을 조정하는 과정을 통해 프로젝트를 둘러싼 근본적인 정황을 파악할 수 있습니다. 이렇듯 높은 이해력을 적절히 발휘하여 리서치 방법과 목표를 설정한다면 어떤 디자인 프로젝트라도 최상의 컨디션으로 진행할 수 있습니다.",
        },
        induction: {
          title: "협상가",
          count: 0,
          selected: false,
          content:
            "디자인 프로젝트를 진행함에 있어 자신의 생각을 설득력있게 표현하고, 다른 사람의 말을 적극적으로 듣고자 하는 태도를 갖추고 있습니다. 대화는 종종 갈등을 탐색하고 상호 합의된 해결책을 찾는 가장 좋은 방법입니다. 재능을 발전시켜 숙련된 협상가가 된다면 당신은 팀원들의 긴장을 완화하고, 열린 대화를 장려하며, 적절한 협력관계를 위한 방법을 찾을 수 있을 것입니다. 디자인 과정에서 주변과 긍정적인 관계를 구축하고 유지하는 능력은 무엇보다 중요합니다. 기회가 된다면 당신과 함께 일해보고 싶네요.",
        },
        deduction: {
          title: "전문가",
          count: 0,
          selected: false,
          content:
            "디자인 과정 중에 필연적으로 발생하는 상충적인 관점을 명쾌하고 해결할 준비가 되어 있습니다. 당신은 자신의 의견을 주장하고, 팀과 클라이언트의 이익을 옹호하며, 최선의 결과를 추구하는 데 적극적입니다. 당신과 비슷한 유형의 일류 디자이너는 디자인에 관한 전문성을 공공연히 내세움으로써 신뢰를 높이고 성공적인 결과를 이끌어냅니다. 전문적인 식견과 통찰력은 모든 문제를 뚜렷이 보이게 하는 효과를 발휘합니다. 꾸준히 공부하고 실력을 갈고 닦아 가장 날카로운 상태를 유지하세요. 믿음직한 당신이 프로젝트의 중심에 서서 해결책을 이끌어낸다면 잘못된 카드를 뒤집는 일은 없을 거예요.",
        },
      },
      makes: {
        free: {
          title: "다채로운",
          count: 0,
          selected: false,
          content:
            "서로 다른 정황으로부터 온 다양한 시각기호의 가치를 알아채고 각각의 개성을 발휘함으로써 역동적인 소통의 가능성을 열어주는 표현과 잘 맞습니다. 일률적이고 단순한 형태보다는 다소 이질적이면서 미묘한 차이가 있는 시각 기호를 복합적으로 제시하는데서 오는 풍성한 시각 경험에서 흥미를 느낍니다.",
        },
        structural: {
          title: "구조적인",
          count: 0,
          selected: false,
          content:
            "복잡함 속에서도 어떤 구조를 찾음으로써 시각 언어의 체계적인 측면에 이끌릴 수 있는 성향입니다. 전달하고자 하는 시각 기호를 명확히 파악하거나 표현하는 재능이 있습니다 미묘한 차이를 만들어내기보다는 확실한 대비와 공간구성을 통해 한눈에 큰 힘을 전달하는 시각 경험에 흥미를 느낍니다.",
        },
        storyteller: {
          title: "스토리텔러",
          count: 0,
          selected: false,
          content:
            "주제, 메시지가 세계에 존재하는 양상을 보는 이에게 충실히 설득하는 능력이 강합니다. 이러한 디자인 방법은 정보를 전달하거나 특징을 보여주는 데 있어 유리합니다. 표현 대상을 면밀히 분석하고, 그것의 구조, 형태, 관계를 이해하고, 그 이해를 시각적인 형태로 번역하는 방식으로 디자인 능력을 발전시키는 공부법을 추천합니다. 시각적 공감을 통해 사람들과 연결되는 당신의 능력은 특히 이야기 중심의 디자인에서 강력한 힘을 발휘하여 다수의 호응을 얻는 인기 디자인을 탄생시킬 것입니다.",
        },
        creative: {
          title: "창의 버튼",
          count: 0,
          selected: false,
          content:
            "대상을 본질적인 요소로 환원시킨 시각 기호에 집중함으로써, 사람들로 하여금 강한 감정 반응을 불러일으키고 메시지의 열린 해석을 독려하는 시각 표현에 강합니다. 당신의 디자인은 사람들이 각자 자신의 관점, 경험, 그리고 감정을 바탕으로 한 해석을 도출하는 힘을 지닐 수 있습니다. 이러한 개방성 덕분에 당신의 디자인은 가장 보편적이고 대중적인 언어로 통용될 수도 있습니다. 있는 그대로의 정보 전달을 넘어 감정적이거나 개념적인 측면을 다루는 디자인은 역사 속에서 종종 새로운 시각 언어의 발견으로 이어지기도 했습니다.",
        },
      },
      warning: {
        status: false,
        text: `복사가완료되었습니다.`,
      },
    };
  },
  methods: {
    async copyToClipboard() {
      const ref = this;
      try {
        let currentUrl = window.document.location.href;

        let t = document.createElement("textarea");
        document.body.appendChild(t);
        t.value = currentUrl;
        t.select();
        await document.execCommand("copy");
        document.body.removeChild(t);

        ref.warning.status = true;
        setTimeout(function () {
          ref.warning.status = false;
        }, 2000);
      } catch (err) {
        console.error("복사 실패: ", err);
      }
    },
    start() {
      this.intro = false;
    },
    prev() {
      if (this.order <= 0) return;
      this.order--;
    },
    next() {
      // if (this.order >= this.contents.length - 1) return;
      if (this.current.answer == null && (this.current.type == "question" || this.current.type == "question_img")) return;
      this.order++;
    },
    get_answer(option) {
      this.current.answer = option.value;
      this.current.options.forEach((opt) => {
        opt.selected = opt.id === option.id;
      });
    },
    reset() {
      this.order = 0;
      this.contents.forEach((content) => {
        if (content.type == "question_img" || content.type == "question") {
          content.answer = null;
          content.options.forEach((option) => {
            option.selected = false;
          });
        }
      });
      //  초기화
      for (const abil in this.ability) {
        this.ability[abil] = 0;
      }
    },
    share_twitter() {
      window.open("http://twitter.com/share?url=" + encodeURIComponent(location.href) + "&text=" + document.querySelector("h1").innerText, "_blank", "width=650,height=600");
    },
    share_facebook() {
      window.open("http://www.facebook.com/share.php?u=" + encodeURIComponent(location.href), "_blank", "width=650,height=600");
    },
  },
  computed: {
    current() {
      return this.contents[this.order];
    },
  },
  mounted() {
    const ref = this;
    ref.st_width = window.innerWidth;

    addEventListener("resize", function () {
      ref.st_width = window.innerWidth;
    });
  },
  watch: {
    ability: {
      deep: true,
      handler() {
        this.radar_data_1.datasets[0].data = [this.ability.embrace, this.ability.induction, this.ability.unique, this.ability.deduction];
        this.radar_data_2.datasets[0].data = [this.ability.free, this.ability.creative, this.ability.structural, this.ability.storyteller];
      },
    },
    order() {
      // order 가 마지막에 도달했을 때 실행하도록 하는 함수
      if (this.order == this.contents.length) {
        for (const abil in this.ability) {
          this.ability[abil] = 0;
        }
        this.contents.forEach((content) => {
          if (content.answer !== undefined) {
            if (Array.isArray(content.answer)) {
              content.answer.forEach((answer) => {
                this.ability[answer]++;
              });
            } else {
              this.ability[content.answer]++;
            }
          }
        });

        // 초기화
        for (const think in this.thinks) {
          this.thinks[think].selected = false;
        }
        for (const make in this.makes) {
          this.makes[make].selected = false;
        }

        // unique vs embrace
        this.ability["unique"] > this.ability["embrace"] ? (this.thinks.unique.selected = true) : (this.thinks.embrace.selected = true);
        // induction vs deduction
        this.ability["induction"] > this.ability["deduction"] ? (this.thinks.induction.selected = true) : (this.thinks.deduction.selected = true);
        // free vs creative
        this.ability["free"] > this.ability["creative"] ? (this.makes.free.selected = true) : (this.makes.creative.selected = true);
        // structural vs storytellr
        this.ability["structural"] > this.ability["storyteller"] ? (this.makes.structural.selected = true) : (this.makes.storyteller.selected = true);
      }
    },
  },
};
</script>

<style lang="scss">
$main-color: #eee;
// Pretendard
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.8/dist/web/variable/pretendardvariable.css");
@import url("https://fonts.googleapis.com/css2?family=Gothic+A1:wght@400;500&display=swap");
* {
  box-sizing: border-box;
}
body,
html {
  font-family: "Gothic A1", sans-serif;
  font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    sans-serif;
  line-height: 1.45;
  font-size: 18px;
  margin: 0;
  padding: 0;
  word-break: keep-all;
  // overflow: hidden;
}

h1,
p {
  margin: 0;
  font-size: 1rem;
}
h1 {
  //   color: #9fa5d4;
  font-size: 1.3rem;
  margin-bottom: 1rem;
  font-weight: 500;
}

main {
  height: 100vh;
}
.content {
  display: grid;
  height: 100%;
  grid-template-rows: 33.333vh 66.666vh;
  .indication {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    text-align: center;
    padding-top: 30px;
    article {
      max-width: 450px;
      margin: 0 auto;
      p {
        margin-bottom: 0;
      }
    }
  }
  &--horizontal {
    .options {
      height: 100%;
      article {
        height: 100%;
        display: flex;
        .img-container {
          width: 100%;
          height: 100%;
          padding: 16px;
          background: white;
          cursor: pointer;
          .img {
            width: 100%;
            height: 100%;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
          }
        }
      }
    }
  }
  &--vertical {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    .options {
      height: 100%;
      article {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .img-container {
          width: 100%;
          height: 100%;
          cursor: pointer;
          padding: 8px;
          background: white;

          .img {
            width: 100%;
            height: 100%;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
          }
        }
      }
    }
  }
  &--intro {
    grid-template-rows: 1fr;
  }
}

.options {
  background: $main-color;
  display: flex;
  justify-content: center;
  align-items: center;

  article {
    width: 100%;
    height: 100%;
    display: flex;
    gap: 8px;
    padding: 8px;
    li {
      list-style: none;
      height: 100%;
      width: 100%;
      padding: 20px;
      background: white;

      text-align: center;
      cursor: pointer;

      .text {
        height: 100%;
      }
      &:hover {
        background: $main-color;
        color: black;
      }
      &.selected {
        background: $main-color;
        color: black;
      }
    }
    .img-container {
      &:hover {
        background: $main-color;
      }
    }
  }
}

.navigation {
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  &__progress-container {
    height: 8px;
    background: $main-color;
    div {
      height: 100%;
      background: #ddd;
      transition: width 0.4s;
    }
  }
  &__arrows {
    display: flex;
    justify-content: space-between;
    text-align: center;
    div {
      padding: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $main-color;
      font-weight: 600;
      color: black;
      width: 40px;
      height: 40px;
      cursor: pointer;
      z-index: 9;

      svg {
        width: 12px;
      }
    }
    .next {
      transform: rotate(180deg);
    }
  }
}
.question-number {
  font-weight: 500;
}
.st0 {
  fill: black;
}
.st1 {
  fill: none;
  stroke: black;
  stroke-miterlimit: 10;
}
polygon {
  fill: black;
}

::selection {
  background: transparent;
  color: black;
}
.button {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: black;
  font-weight: 500;
}
.wavy {
  height: 100%;
  width: 100%;
  display: flex;
  // align-items: center;
  padding-top: 20px;
  justify-content: center;
  position: relative;
  span {
    display: inline-block;
    position: relative;
    animation: waviy 1.5s infinite;
    animation-delay: calc(0.1s * var(--i));
  }
  cursor: pointer;
}

.result {
  justify-content: center;
  display: flex;
  // align-items: center;
  height: auto;
  text-align: center;
  h1 {
    padding: 40px;
    margin-bottom: 0;
    text-align: center;
    background: white;
  }
  article {
    width: 100%;
    padding: 0;
    height: 100vh;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    line-height: 1.6;
  }
  &__left {
    background-position: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 40px;
    background-size: auto 100%;
    height: 100%;
  }
  &__right {
    background: $main-color;
    text-align: left;
    width: 100%;
    height: 100%;
    overflow: auto;
    line-height: 1.7;
    padding: 8px;
    padding-top: 0;
    h2 {
      margin-bottom: 0.25rem;
      font-weight: 500;
    }
    ul {
      margin: 0;
      padding: 0;
    }
    li {
      list-style: none;
      padding: 0;
      margin-bottom: 0;
      p {
        font-size: 14px;
        margin-bottom: 0.5em;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    .make,
    .think {
      padding: 30px;
      background: white;
    }
    .think {
      margin-bottom: 8px;
    }
  }
}

.graph-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  gap: 8px;
  padding: 8px 0;
  background: $main-color;
  width: 100%;
  .graph {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
    padding-bottom: 30px;
    background: white;
    .title {
      text-align: center;
      font-weight: 500;
    }
  }
}

.reset {
  background: $main-color;
  padding: 8px 16px;
  cursor: pointer;
  margin-top: 60px;
  span {
    display: inline-block;
    position: relative;
    animation: waviy 1.5s infinite;
    animation-delay: calc(0.1s * var(--i));
  }
}

h2,
h3 {
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
}
h2 {
  margin-bottom: 2rem;
}
p {
  margin-bottom: 1rem;
  font-weight: 500;
}
t {
  margin-right: 0.24rem;
}
.answer-indicator {
  font-weight: 500;
  margin-bottom: 1rem;
}

#ds-logo {
  position: fixed;
  bottom: 15px;
  width: 150px;
  left: 25px;
  //   transform: translateX(-50%);
  img {
    width: 100%;
  }
}

.intro {
  background-size: auto 100%;
  background-position: center center;
  background-repeat: repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  .intro-title {
    display: block;
    width: 20%;
    margin: 0 auto;
    min-width: 400px;
    margin-bottom: 70px;
  }
  .intro-tabstart {
    width: 10%;
    min-width: 280px;
    display: block;
    margin: 0 auto;
    animation: blink 1s infinite;
  }
}

.go-to-dc {
  width: 300px;
  img {
    width: 100%;
  }
}

.go-share {
  width: 320px;
  img {
    width: 100%;
  }
}

.retry {
  width: 280px;
  cursor: pointer;
  img {
    width: 100%;
  }
}

.sns {
  display: flex;
  justify-content: center;
  gap: 20px;
  img {
    width: 50px;
    cursor: pointer;
  }
}

#warning {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  font-size: 14px;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(7px);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  .text {
    padding: 5px 10px;
    color: black;
    background: #eee;
  }
}

@keyframes blink {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.02);
  }
}
@keyframes waviy {
  0%,
  40%,
  100% {
    transform: translateY(0);
  }
  20% {
    // opacity: 0.41;
    transform: translateY(-6px);
  }
}

@media all and (max-width: 800px) {
  .navigation {
    &__arrows {
      div {
        width: 50%;
        height: 20px;
      }
    }
  }
  #ds-logo {
    left: 50%;
    transform: translateX(-50%);
  }
  .content {
    &--vertical {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 2fr;
      .options {
        article {
          align-items: center;
        }
      }
    }
  }
  .graph-container {
    grid-template-columns: 1fr;
    display: flex;
    flex-direction: column;
  }
  main.result {
    article {
      height: auto;

      grid-template-columns: 1fr;
      //   padding-top: 60px;
    }
  }
  .result {
    &__right {
      order: 1;
      padding-top: 8px;
    }
    &__left {
      order: 2;
      padding: 20px;
      padding-top: 130px;
      padding-bottom: 130px;
      background-size: cover;

      .graph-container {
        padding-right: 8px;
        padding-bottom: 0;
      }
      .reset {
        display: none;
      }
    }
  }
  .reset.mobile {
    text-align: center;
    margin-top: 8px;
    background: white;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .options {
    article {
      li {
        &:hover {
          background: white;
        }
      }
      .img-container {
        &:hover {
          background: white;
        }
      }
    }
  }
  .go-to-dc {
    width: 90%;
  }
  .go-share {
    width: 95%;
  }
  .retry {
    width: 80%;
  }
  .sns {
    gap: 15px;
    img {
      width: 40px;
    }
  }
  .intro {
    background-size: cover;
    .intro-title {
      width: 100%;
      min-width: 0;
      padding: 20px;
      max-width: 400px;
    }
    .intro-tabstart {
      width: 95%;
      min-width: 0;
      padding: 0 20px;
      max-width: 400px;
    }
  }
}
</style>
